// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

//I put this here becase no matter how I tried to import rails ujs I just would not make itself avaialable in
global.Rails = Rails

//installed bootstrap.js (and popper/core) with yarn
import "bootstrap"
// import { createPopper } from '@popperjs/core';
import "@fortawesome/fontawesome-free/js/all"
import flatpickr from "flatpickr"

// import "../lib/theme/js/theme"
// import "../lib/theme/js/config"

// Hold all the stylesheets in a styles folder within /app/frontend
// import "../styles/application"

// Utils contain any small/custom JS we want to write ourselves
import "./utils"

// Copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Detect user time zone
// import JSTZ from 'jstimezonedetect';
// document.cookie = "time_zone_name=" + JSTZ.determine().name();
