import List from "list.js" // sortable tables

const tableContainerId = "link-builder-links";

document.addEventListener("turbolinks:load", () => {
  const table = document.getElementById(tableContainerId);
  if (!table) { return; }

  const options = {
    valueNames: [ 'medium', 'source', 'content' ]
  };

  const list = new List(tableContainerId, options);
})
