// This script was copied from Portal and was modified to work with a text (vs an input field).
// TODO: how do we merge it with Portal?

import { Toast } from 'bootstrap';

const copyButtonSelector = '.copy-button';
const toastSelector = "#copy-button-toast";

document.addEventListener("turbolinks:load", () => {
  initCopyButton();
});

function initCopyButton() {
  const copyButton = document.querySelectorAll(copyButtonSelector);
  const toast = new Toast(document.querySelector(toastSelector));

  if (copyButton) {
    copyButton.forEach( btn => {
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        var copyTarget = document.querySelector(btn.dataset.target);

        var copyValue = copyTarget?.value;
        if (!copyValue) {
          copyValue = copyTarget?.textContent;
        }

        if (copyValue) {
          // copyTarget.select(); // commented out because this only works for inputs
          navigator.clipboard.writeText(copyValue);
          toast.show();
        } else {
          console.error("copy-button target element not found");
        };
      });
    });
  };
}
